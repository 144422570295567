import { Contexto } from '../environment.types';

export const ibmec: Contexto[] = [
  {
    host: 'http://localhost:3002',
    portal: 'https://dev.portal.ibmec.br/experimente',
    home: 'https://dev.portal.ibmec.br/',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    env: 'local',
    brand: 'ibmec',
    candidato: 'https://candidatos.dev.portal.ibmec.br/login',
  },
];
