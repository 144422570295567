import { Contexto } from '../environment.types';

export const wyden: Contexto[] = [
  {
    host: 'http://localhost:3003',
    portal: 'https://dev.portal.wyden.com.br/experimente',
    home: 'https://dev.portal.wyden.com.br/',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    env: 'local',
    brand: 'wyden',
    candidato: 'https://candidatos.dev.portal.wyden.com.br/login',
  },
];
