import { BrowserCacheLocation } from '@azure/msal-browser';
import { candidato } from '../../environment/environment.configs';
import { IAuthConfig } from '../auth.types';
import { loggerCallback } from '../logger/logger';

//  Ítem mockado para testes integrados
export const yduqs: IAuthConfig[] = [
  {
    envs: ['local'],
    brands: ['yduqs'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '567b6a3f-5bfe-4abb-93ca-496d0c5cacf6',
          authority: 'https://login.dev.wyden.com.br/wydendev.onmicrosoft.com/B2C_1_sava_candidato_sigin',
          knownAuthorities: ['login.dev.wyden.com.br', 'wydendev.b2clogin.com'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${candidato}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydendev.onmicrosoft.com/567b6a3f-5bfe-4abb-93ca-496d0c5cacf6/user.read',
      },
    },
    applicationUrl: 'http://localhost',
  },
];
